import { Controller } from '@hotwired/stimulus'
import * as Sentry from '@sentry/browser';

export default class extends Controller {
  static targets = [
    'previewImage', 'photoUrlInput', 'attachmentIdInput', 'modal', 'thumbnailCard',
  ]
  static values = {
    photosPath: String,
    page: { type: Number, default: 1 },
  }

  showPhotoChooser() {
    $(this.modalTarget).modal('show')

    if (!this.hasThumbnailCardTarget) {
      this.loadMoreThumbnails()
    }
  }

  hidePhotoChooser() {
    $(this.modalTarget).modal('hide')
  }

  loadMoreThumbnails() {
    $.getJSON(this.photosPathValue, { page: this.pageValue }, (photos) => {
      this.pageValue += 1
      return photos.forEach(photo => (
        $('.thumbnails', this.element).append(
          // eslint-disable-next-line max-len
          `<div class='col-md-3'><div class='card p-2 mb-2' data-action="click->conference-reports--choose-photos--edit#selectThumbnail" data-conference-reports--choose-photos--edit-target="thumbnailCard" data-post-id='${photo.id}' data-large-photo-url='${photo.large_photo_url}'><a href='#' class='thumbnail'><img src='${photo.photo_url}' class='card-img-top'/></a></div></div>`)
      ))
    })
  }

  selectedPhoto() {
    return fetch(this.selectedPhotoUrl())
      .then(response => response.blob())
      .catch((error) => {
        Sentry.captureException(error)
        return undefined
      })
  }

  selectedPhotoUrl() {
    return this.selectedThumbnail().dataset.largePhotoUrl
  }

  selectedThumbnail() {
    return this.selectedThumbnails()[0]
  }

  selectedThumbnails() {
    return this.modalTarget.getElementsByClassName('selected')
  }

  deselectThumbnails() {
    Array.from(this.selectedThumbnails()).forEach(thumbnail => thumbnail.classList.remove('selected'))
  }

  selectThumbnail(event) {
    this.deselectThumbnails()
    event.currentTarget.classList.add('selected')
  }

  async choosePhoto() {
    if (this.selectedThumbnail()) {
      this.photoUrlInputTarget.value = this.selectedPhotoUrl()
      this.attachmentIdInputTarget.value = ''
      this.previewImageTarget.src = this.selectedPhotoUrl()
    }

    this.hidePhotoChooser()
  }
}
